import React, { useEffect, useState } from "react";
import { Button, Drawer, Tooltip, Row, Col, Popover, Spin } from "antd";
// @ts-ignore
import cornerstone from "cornerstone-core";
// @ts-ignore
import cornerstoneMath from "cornerstone-math";
// @ts-ignore
import cornerstoneTools from "cornerstone-tools";
// @ts-ignore
import Hammer from "hammerjs";
// @ts-ignore
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import dicomParser from "dicom-parser";
import {
  ArrowLeftOutlined,
  DownloadOutlined,
  EditOutlined,
  FormOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { wadoUriMetaDataProvider } from "./metaDataProvider";
import { DicomData } from "../../../models/DicomData/dicomData.model";
import AuthContainer from "../../../store/container/AuthContainer";
import { Link, useParams } from "react-router-dom";
import "./caseViewer.scss";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import ReportForm from "../ReportForm";
import { CaseDetail } from "../../../models/CaseDetail/caseDetail.model";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { UserRoleEnum } from "../../../enums/userRole.enum";
import AdminCaseService from "../../../services/Case/AdminCase/adminCase.service";
import { CaseStatusEnum } from "../../../enums/caseStatus.enum";
import DoctorCaseService from "../../../services/Case/DoctorCase/doctorCase.service";
import InfiniteScroll from "react-infinite-scroller";
import { FileOutlined, LockOutlined } from "@ant-design/icons";
import AppLoader from "../AppLoader";
import { DicomDataThumbnail } from "../../../models/DicomDataThumbnail/dicomDataThumbnail.model";
import { deserialize } from "serializr";
import moment from "moment";

interface CaseViewerParams {
  caseId: string;
}

cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

cornerstoneTools.init({
  mouseEnabled: true,
  touchEnabled: true,
  globalToolSyncEnabled: false,
  showSVGCursors: false,
});

const fontFamily =
  "Work Sans, Roboto, OpenSans, HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif";

cornerstoneTools.textStyle.setFont(`16px ${fontFamily}`);

// Set the tool width
cornerstoneTools.toolStyle.setToolWidth(2);

// Set color for inactive tools
cornerstoneTools.toolColors.setToolColor("rgb(255, 255, 0)");

// Set color for active tools
cornerstoneTools.toolColors.setActiveColor("rgb(0, 255, 0)");

cornerstone.metaData.addProvider(wadoUriMetaDataProvider);

// const caseService = new CaseService();

const setZoomActive = (e: any) => {
  const ZoomMouseWheelTool = cornerstoneTools.ZoomMouseWheelTool;

  cornerstoneTools.addTool(ZoomMouseWheelTool);
  cornerstoneTools.setToolActive("ZoomMouseWheel", { mouseButtonMask: 1 });
  const PanTool = cornerstoneTools.PanTool;

  cornerstoneTools.addTool(PanTool);
  cornerstoneTools.setToolActive("Pan", { mouseButtonMask: 1 });
};

const setMouseWheelActive = (e: any) => {
  const StackScrollMouseWheelTool = cornerstoneTools.StackScrollMouseWheelTool;
  cornerstoneTools.addTool(StackScrollMouseWheelTool);
  cornerstoneTools.setToolActive("StackScrollMouseWheel", {});
};

const setLengthActive = (e: any) => {
  const LengthTool = cornerstoneTools.LengthTool;
  cornerstoneTools.addTool(LengthTool);
  cornerstoneTools.setToolActive("Length", { mouseButtonMask: 1 });
};

const setWwwcActive = (e: any) => {
  const WwwcTool = cornerstoneTools.WwwcTool;
  cornerstoneTools.addTool(WwwcTool);
  cornerstoneTools.setToolActive("Wwwc", { mouseButtonMask: 1 });
};

const setEraserActive = (e: any) => {
  const EraserTool = cornerstoneTools.EraserTool;
  cornerstoneTools.addTool(EraserTool);
  cornerstoneTools.setToolActive("Eraser", { mouseButtonMask: 1 });
};

interface CaseViewerProps extends AuthReducerProps {}

function CaseViewer({ userRole }: CaseViewerProps) {
  const params: CaseViewerParams = useParams();

  const [caseDetail, setCaseDetail] = useState<CaseDetail>();

  const [downloadLoading, setDownloadLoading] = useState(false);

  const [drawerVisible, setDrawerVisible] = useState(false);

  const [imageIds, setImageIds] = useState<string[]>([]);

  const [activeImageId, setActiveImageId] = useState(null);

  const [metaData, setMetaData] = useState<any>();

  const [dicomFiles, setDicomFiles] = useState<DicomData[]>([]);

  const [dicomDirectories, setDicomDirectories] = useState<
    DicomDataThumbnail[]
  >([]);

  const [activeDirectoryName, setActiveDirectoryName] = useState<
    string | undefined
  >();

  const [loading, setLoading] = useState(false);

  const [overviewLoading, setOverviewLoading] = useState(false);

  let element = document.getElementById("dicomImage");

  const handleDrawerOpen = () => setDrawerVisible(true);

  const handleDrawerClose = () => setDrawerVisible(false);

  const handleShowCase = () => {
    if (params.caseId) {
      const showDicom =
        userRole === UserRoleEnum.ADMIN
          ? AdminCaseService.showAdminCaseDirectories
          : DoctorCaseService.showDoctorCaseDirectories;
      setLoading(true);
      showDicom(
        Number(params.caseId),
        (dicomDataThumbnails: DicomDataThumbnail[]) => {
          dicomDataThumbnails.forEach(
            (dicomDataThumbnail) =>
              (dicomDataThumbnail.itemId =
                "wadouri:" + dicomDataThumbnail.signedUrl)
          );
          setDicomDirectories(dicomDataThumbnails);
          setTimeout(() => {
            let i = 0;
            dicomDataThumbnails.forEach(
              async (dicomDataThumbnail: DicomDataThumbnail) => {
                const thumbnailElement = document.getElementById(
                  "thumbnail_" + dicomDataThumbnail.itemId
                );
                if (thumbnailElement) {
                  cornerstone.enable(thumbnailElement);
                  const image = await cornerstone.loadAndCacheImage(
                    dicomDataThumbnail.itemId
                  );
                  cornerstone.displayImage(thumbnailElement, image);
                  if (i === dicomDataThumbnails.length - 1) {
                    handleFetchCaseFiles(dicomDataThumbnails);
                  }
                  i++;
                }
              }
            );
          }, 500);

          /*setDicomFiles(dicomFiles);
          const imageIds = dicomFiles.map((fileItem) => {
            return "wadouri:" + fileItem.file;
          });
          setImageIds(imageIds);
          const stack = {
            currentImageIdIndex: 0,
            imageIds,
          };
          cornerstone.loadAndCacheImage(imageIds[0]).then((image: any) => {
            const {
              getNumberValue,
              getValue,
            } = cornerstoneWADOImageLoader.wadors.metaData;
            const metaData = cornerstone.metaData.get(
              "imagePlaneModule",
              imageIds[0]
            );
            const patientData = cornerstone.metaData.get(
              "patientModule",
              imageIds[0]
            );
            cornerstone.displayImage(element, image);
            cornerstoneTools.addStackStateManager(element, ["stack"]);
            cornerstoneTools.addToolState(element, "stack", stack);
          });

          setTimeout(() => {
            imageIds.forEach((imageId: any) => {
              // const thumbnailElement = document.getElementById(imageId);
              // if (thumbnailElement) {
              //   cornerstone.enable(thumbnailElement);
              //   const image = await cornerstone.loadAndCacheImage(imageId);
              // cornerstone.loadImage(imageId).then((image: any) => {
              //   console.log(
              //       cornerstone.metaData.get("patientModule", imageId)
              //   );
              //   cornerstone.displayImage(thumbnailElement, image);
              //   cornerstoneTools.addStackStateManager(element, ["stack"]);
              //   cornerstoneTools.addToolState(element, "stack", stack);
              // });
              // }
            });
          }, 3000);

          const StackScrollMouseWheelTool =
            cornerstoneTools.StackScrollMouseWheelTool;
          cornerstoneTools.addTool(StackScrollMouseWheelTool);
          cornerstoneTools.setToolActive("StackScrollMouseWheel", {});*/
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  };

  const handleDownloadDetails = () => {
    const downloadCase =
      userRole === UserRoleEnum.ADMIN
        ? AdminCaseService.downloadAdminCase
        : DoctorCaseService.downloadDoctorCase;
    setDownloadLoading(true);
    if (params.caseId) {
      downloadCase(
        Number(params.caseId),
        (targetUrl: any) => {
          window.open(targetUrl, "_blank");
        },
        () => {},
        () => {
          setDownloadLoading(false);
        }
      );
    }
  };

  const handleFetchCaseFiles = async (
    dicomDirectories: DicomDataThumbnail[]
  ) => {
    setOverviewLoading(true);
    let activeDirectoryName;
    for (let i = 0; i < dicomDirectories.length; i++) {
      dicomDirectories[i].isLoading = true;
      setDicomDirectories([...dicomDirectories]);
      const dicomDirectory = dicomDirectories[i];
      const showMethod =
        userRole === UserRoleEnum.ADMIN
          ? AdminCaseService.showAdminCaseDirectoryFiles
          : DoctorCaseService.showDoctorCaseDirectoryFiles;
      const directoryFilesJSON = await showMethod(
        Number(params.caseId),
        dicomDirectory.folder ?? ""
      );
      const directoryFiles = deserialize(
        DicomData,
        directoryFilesJSON.data as any[]
      );
      const promises = directoryFiles.map(async (dicomData) => {
        return await cornerstone.loadAndCacheImage("wadouri:" + dicomData.file);
      });
      try {
        const images = await Promise.all(promises);
      } catch (e) {
      }
      if (!activeDirectoryName) {
        activeDirectoryName = dicomDirectories[0].folder;
        setActiveDirectoryName(activeDirectoryName);
        setOverviewLoading(false);
      }
      dicomDirectories[i].isLoading = false;
      dicomDirectories[i].isLocked = false;
      dicomDirectories[i].files = directoryFiles;
      setDicomDirectories([...dicomDirectories]);
    }
  };

  const loadImage = (imageIds: string[]) => {
    // setActiveImageId(imageId);
    const imageId = imageIds[0];
    element = document.getElementById("dicomImage");
    cornerstone.enable(element);
    const stack = {
      currentImageIdIndex: 0,
      imageIds,
    };
    cornerstone.loadAndCacheImage(imageId).then((image: any) => {
      cornerstone.displayImage(element, image);
      cornerstoneTools.addStackStateManager(element, ["stack"]);
      cornerstoneTools.addToolState(element, "stack", stack);
    });
    const StackScrollMouseWheelTool =
      cornerstoneTools.StackScrollMouseWheelTool;
    cornerstoneTools.addTool(StackScrollMouseWheelTool);
    cornerstoneTools.setToolActive("StackScrollMouseWheel", {});
  };

  useEffect(() => {
    if (activeDirectoryName && dicomDirectories.length > 0) {
      const directoryIndex = dicomDirectories.findIndex(
        (directory) => directory.folder === activeDirectoryName
      );
      if (directoryIndex >= 0) {
        if (dicomDirectories[directoryIndex].files.length > 0) {
          loadImage(
            dicomDirectories[directoryIndex].files.map((file) =>
              file ? "wadouri:" + file.file : ""
            )
          );
        }
      }
    }
  }, [activeDirectoryName, dicomDirectories]);

  useEffect(() => {
    if (params.caseId && userRole) {
      const showCase =
        userRole === UserRoleEnum.ADMIN
          ? AdminCaseService.showAdminCase
          : DoctorCaseService.showDoctorCase;
      showCase(
        Number(params.caseId),
        (caseDetail: CaseDetail) => {
          setCaseDetail(caseDetail);
        },
        () => {},
        () => {}
      );
    }
  }, [params.caseId, userRole]);

  useEffect(() => {
    if (userRole) {
      element = document.getElementById("dicomImage");
      cornerstone.enable(element);
      handleShowCase();
      if (element) {
        element.addEventListener("cornerstoneimagerendered", function (e: any) {
          const eventData = e.detail;
          // console.log("Event data", eventData);
          const metaData = cornerstone.metaData.get(
            "imagePlaneModule",
            eventData.image.imageId
          );
          const studyData = cornerstone.metaData.get(
            "generalStudyModule",
            eventData.image.imageId
          );
          const patientData = cornerstone.metaData.get(
            "patientModule",
            eventData.image.imageId
          );
          setMetaData({
            ...patientData,
            ...studyData,
            renderTime: eventData.renderTimeInMs + " ms",
            wwwl:
              Math.round(eventData.viewport.voi.windowWidth) +
              "/" +
              Math.round(eventData.viewport.voi.windowCenter),
            zoom: eventData.viewport.scale.toFixed(2),
          });
        });
      }
      /* cornerstone.events.addEventListener(
        "cornerstoneimagerendered",
        function (e: any) {
          const eventData = e.detail;
          console.log("Event data", eventData);
        }
      );*/
      const appRoutesNode = document.getElementById("appRoutes");
      if (appRoutesNode) {
        appRoutesNode.classList.add("dicom-viewer-mode");
      }
      return () => {
        if (appRoutesNode) appRoutesNode.classList.remove("dicom-viewer-mode");
      };
    }
  }, [userRole]);

  return (
    <div className="case-detail">
      {loading ? <AppLoader loading={loading} /> : null}
      <div className="case-detail__tool-bar">
        <Link to={AppRoutes.HOME}>
          <Button type="primary">
            <ArrowLeftOutlined />
            Back
          </Button>
        </Link>
        <Button type="primary" onClick={setZoomActive}>
          Zoom/Pan
        </Button>
        <Button type="primary" onClick={setMouseWheelActive}>
          Scroll
        </Button>
        <Button type="primary" onClick={setLengthActive}>
          Length
        </Button>
        {/*<Button type="primary" onClick={setWwwcActive}>
          WWWC
        </Button>*/}
        <Button type="primary" onClick={setEraserActive}>
          Eraser
        </Button>
        <Tooltip title="Download case">
          <Button
            type="primary"
            onClick={handleDownloadDetails}
            icon={<DownloadOutlined />}
            loading={downloadLoading}
          />
        </Tooltip>
        <Popover
          destroyTooltipOnHide
          overlayClassName="case-viewer__patient-popover"
          placement="bottomLeft"
          content={
            <div className="case-viewer__patient-details">
              <Row gutter={[20, 15]}>
                <Col span={6}>
                  <div className="case-viewer__label">Patient ID</div>
                  <div className="case-viewer__value">
                    {caseDetail?.patientId}
                  </div>
                </Col>
                <Col span={6}>
                  <div className="case-viewer__label">Patient name</div>
                  <div className="case-viewer__value">
                    {caseDetail?.patientName}
                  </div>
                </Col>
                <Col span={6}>
                  <div className="case-viewer__label">Age</div>
                  <div className="case-viewer__value">
                    {caseDetail?.age} Years
                  </div>
                </Col>
                <Col span={6}>
                  <div className="case-viewer__label">Gender</div>
                  <div className="case-viewer__value">{caseDetail?.age}</div>
                </Col>
                <Col span={6}>
                  <div className="case-viewer__label">Scan Type</div>
                  <div className="case-viewer__value">
                    {caseDetail?.scanTypeName}
                  </div>
                </Col>
                <Col span={6}>
                  <div className="case-viewer__label">Body parts</div>
                  <div className="case-viewer__value">
                    {caseDetail?.caseBodyParts
                      ?.map((bodyPart) => bodyPart.bodyPartName)
                      .join(", ")}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="case-viewer__label">Patient History</div>
                  <div className="case-viewer__value">
                    {caseDetail?.patientHistory}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="case-viewer__label">Referring Doctor</div>
                  <div className="case-viewer__value">
                    {caseDetail?.referringDoctor}
                  </div>
                </Col>
              </Row>
            </div>
          }
          title="Patient info"
          trigger="click"
        >
          <Button type="primary">Patient Info</Button>
        </Popover>
        {caseDetail?.status === CaseStatusEnum.ASSIGNED && (
          <Button type="primary" onClick={handleDrawerOpen}>
            <FormOutlined />
            Generate Report
          </Button>
        )}
      </div>
      <div className="dicom-wrapper">
        <div className="thumbnail-selector">
          <div className="thumbnailList" id="thumbnail-list">
            <InfiniteScroll
              pageStart={0}
              loadMore={() => {}}
              hasMore={false}
              loader={
                <div className="loader" key={0}>
                  Loading ...
                </div>
              }
            >
              {dicomDirectories.map((dicomDirectory, i) => {
                return (
                  <div
                    key={dicomDirectory.signedUrl}
                    className={
                      activeDirectoryName === dicomDirectory.folder
                        ? "thumbnail-item-wrapper active"
                        : "thumbnail-item-wrapper"
                    }
                    onClick={() => {
                      !dicomDirectory?.isLocked &&
                        setActiveDirectoryName(dicomDirectory.folder);
                    }}
                  >
                    <div
                      id={"thumbnail_" + dicomDirectory.itemId}
                      className="thumbnail-item"
                      onContextMenu={() => false}
                      unselectable="on"
                      onMouseDown={() => false}
                      onSelect={() => false}
                    />
                    <div className="thumbnail-item-scrollable" />
                    <div className="thumbnail-item__count">
                      <FileOutlined /> {dicomDirectory?.fileCount}
                    </div>
                    {dicomDirectory?.isLoading ? (
                      <div className="thumbnail-item__loading-icon">
                        <span className="thumbnail-item__loading-text">
                          <Spin
                            indicator={
                              <LoadingOutlined style={{ fontSize: 10 }} spin />
                            }
                          />
                        </span>
                      </div>
                    ) : dicomDirectory?.isLocked ? (
                      <div className="thumbnail-item__lock-icon">
                        <LockOutlined />
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        </div>
        <div
          onContextMenu={() => false}
          className="dicom-viewer"
          unselectable="on"
        >
          <div id="dicomImage"></div>
          {overviewLoading && (
            <div className="case-viewer__overview-loading">
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 36 }} spin />}
              />{" "}
              Loading...
            </div>
          )}
          {metaData && (
            <React.Fragment>
              <div className="case-viewer__patient-details">
                <div> Patient ID: {metaData?.patientId}</div>
                <div> Patient Name: {metaData?.patientName}</div>
                <div> Series number: {metaData?.seriesNumber}</div>
              </div>
              <div className="case-viewer__study-details">
                <div> Study description: {metaData?.studyDescription}</div>
                <div>
                  {" "}
                  Study date: {moment(metaData?.studyDate).format("DD/MM/YYYY")}
                </div>
                {/*<div> Study Instance: {metaData?.studyInstanceUID}</div>*/}
                {/*<div> Series instance: {metaData?.seriesInstanceUID}</div>*/}
                {/*<div> SOP Instance: {metaData?.sopInstanceUID}</div>*/}
              </div>
              <div className="case-viewer__image-details">
                <div> Render Time: {metaData?.renderTime}</div>
                <div> WW/WL: {metaData?.wwwl}</div>
                <div> Zoom: {metaData?.zoom}</div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
      <Drawer
        title={<h2>Generate Report</h2>}
        width="70%"
        onClose={handleDrawerClose}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
        maskClosable={false}
        footer={null}
      >
        {caseDetail && <ReportForm caseDetailId={caseDetail.id} />}
      </Drawer>
    </div>
  );
}

export default AuthContainer(CaseViewer);
