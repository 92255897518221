import React, { useEffect, useState } from "react";
import { CaseDetail } from "../../../models/CaseDetail/caseDetail.model";
import AdminCaseService from "../../../services/Case/AdminCase/adminCase.service";
import { CaseStatusEnum } from "../../../enums/caseStatus.enum";
import moment from "moment";
import { Button, Drawer, Menu, Modal, Popover, Table, Tooltip } from "antd";
import AppLoader from "../../../shared/components/AppLoader";
import AppCard from "../../../shared/components/AppCard";
import {
  EyeOutlined,
  DownloadOutlined,
  UserAddOutlined,
  FormOutlined,
  CloudDownloadOutlined,
} from "@ant-design/icons";
import CaseAssignmentForm from "./CaseAssignmentForm";
import { stopPropogateEvent } from "../../../shared/utils/eventUtils";
import ViewAccessForm from "./ViewAccessForm";
import CaseOverview from "../../../shared/components/CaseOverview";
import "./adminCases.scss";
import CaseFilterForm from "../../../shared/components/CaseFilterForm";
import { CaseFilterParams } from "../../../models/CaseFilterParams/caseFilterParams.model";
import { CaseBodyPart } from "../../../models/CaseBodyPart/caseBodyPart.model";
import ReportForm from "../../../shared/components/ReportForm";
import { Admin } from "../../../models/Admin/admin.model";
import ProfileService from "../../../services/Profile/profile.service";
import { UserRoleEnum } from "../../../enums/userRole.enum";
import ReportService from "../../../services/Report/report.service";
import { DownloadFileTypeEnum } from "../../../enums/downloadFileType.enum";
import { formatStatus } from "../../../shared/utils/dataFormatConverter";

interface AdminCasesProps {}

function AdminCases(props: AdminCasesProps) {
  const [caseDetails, setCaseDetails] = useState<CaseDetail[]>([]);

  const [activeCase, setActiveCase] = useState<CaseDetail | undefined>();

  const [loading, setLoading] = useState(false);

  const [downloadLoading, setDownloadLoading] = useState(false);

  const [downloadZipLoading, setDownloadZipLoading] = useState(false);

  const [showCaseOverview, setShowCaseOverview] = useState(false);

  const [showAssignmentPopover, setShowAssignmentPopover] = useState(false);

  const [showViewPopover, setShowViewPopover] = useState(false);

  const [showReportForm, setShowReportForm] = useState(false);

  const [me, setMe] = useState<Admin>();

  const handleToggleReportForm = () => setShowReportForm(!showReportForm);

  const [formValues, setFormValues] = useState<CaseFilterParams>(
    Object.assign(new CaseFilterParams(), {
      ...new CaseFilterParams(),
      status: CaseStatusEnum.REPORT_PENDING,
      fromDate: moment().format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
      scanCenterBranchId: undefined,
    })
  );

  const handleSubmit = (values: CaseFilterParams) => {
    const formValues = Object.assign(new CaseFilterParams(), values);
    setFormValues(formValues);
    handleFetchCases(formValues);
  };

  const handleReset = () => {
    const formValues = new CaseFilterParams();
    setFormValues(formValues);
    handleFetchCases(formValues);
  };

  const handleCloseCaseOverview = () => {
    setActiveCase(undefined);
    setShowCaseOverview(false);
  };

  const handleViewAccessSuccess = () => {
    setActiveCase(undefined);
    setShowViewPopover(false);
  };

  const handleRowChange = (caseDetail: CaseDetail, _: number | undefined) => {
    return {
      onClick: (event: any) => {
        setActiveCase(caseDetail);
        setShowCaseOverview(true);
      },
    };
  };

  const handleFetchCases = (formValues: CaseFilterParams) => {
    setLoading(true);
    AdminCaseService.fetchAdminCases(
      formValues,
      (caseDetails: CaseDetail[]) => {
        setCaseDetails(caseDetails);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  const handleAssignmentSuccess = (caseDetail: CaseDetail) => {
    const caseIndex = caseDetails.findIndex(
      (caseItem) => caseItem.id === caseDetail.id
    );
    if (caseIndex >= 0) {
      caseDetails[caseIndex] = caseDetail;
    }
    setCaseDetails([...caseDetails]);
    setActiveCase(undefined);
    setShowAssignmentPopover(false);
  };

  const handleCaseUpdateSuccess = (caseDetail: CaseDetail) => {
    const caseIndex = caseDetails.findIndex(
      (caseItem) => caseItem.id === caseDetail.id
    );
    if (caseIndex >= 0) {
      caseDetails[caseIndex] = caseDetail;
    }
    setCaseDetails([...caseDetails]);
    setActiveCase(caseDetail);
  };

  const handleDownloadReport = (
    caseDetail: CaseDetail,
    fileType: DownloadFileTypeEnum,
    withLetterHead: boolean
  ) => {
    setActiveCase(caseDetail);
    setDownloadLoading(true);
    if (caseDetail?.id) {
      const apiMethod = caseDetail?.approvedByAdminId
        ? ReportService.downloadApprovalReport
        : ReportService.downloadReport;
      apiMethod(
        fileType,
        caseDetail,
        withLetterHead,
        (reportUrl: string) => {
          window.open(reportUrl, "_blank");
        },
        () => {},
        () => {
          setActiveCase(undefined);
          setDownloadLoading(false);
        }
      );
    }
  };

  const handleDownloadDetails = (caseDetail: CaseDetail) => {
    setActiveCase(caseDetail);
    if (caseDetail?.id) {
      setDownloadZipLoading(true);
      AdminCaseService.downloadAdminCase(
        Number(caseDetail?.id),
        (targetUrl: any) => {
          window.open(targetUrl, "_blank");
        },
        () => {},
        () => {
          setDownloadZipLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    handleFetchCases(formValues);
    ProfileService.getProfile(
      UserRoleEnum.ADMIN,
      (admin: Admin) => {
        setMe(admin);
      },
      () => {},
      () => {}
    );
  }, []);

  const columns = [
    {
      title: "Patient name",
      dataIndex: "patientName",
      key: "patientName",
    },
    {
      title: "Scan Center",
      dataIndex: "scanCenterName",
      key: "scanCenterName",
    },
    {
      title: "Date Uploaded",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => (
        <span>{date ? moment(date).format("DD-MMM-YYYY hh:mm A") : "NA"}</span>
      ),
    },
    {
      title: "Scan Type",
      dataIndex: "scanTypeName",
      key: "scanTypeName",
    },
    {
      title: "Body parts",
      dataIndex: "caseBodyParts",
      key: "caseBodyParts",
      render: (caseBodyParts: CaseBodyPart[]) => (
        <div>
          {caseBodyParts.map((bodyPart) => bodyPart.bodyPartName).join(", ")}
        </div>
      ),
    },
    {
      title: "Assigned Doctor",
      dataIndex: "assignedToDoctorName",
      key: "assignedToDoctorName",
      render: (doctorName: string) => <span>{doctorName || "NA"}</span>,
    },
    {
      title: "Due Time",
      dataIndex: "dueDate",
      key: "dueDate",
      render: (time: string, caseDetail: CaseDetail) =>
        caseDetail?.status !== CaseStatusEnum.REPORTED ? time : null,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string, caseDetail: CaseDetail) => {
        const showGenerateReport =
          caseDetail?.status === CaseStatusEnum.TO_BE_APPROVED ||
          (caseDetail?.status === CaseStatusEnum.ASSIGNED &&
            me?.id === caseDetail?.assignedToAdminId);
        return (
          <div
            className="admin-cases__status-wrapper"
            onClick={stopPropogateEvent}
          >
            <span className={`admin-cases__status ${status}`}>
              {formatStatus(status)}
            </span>
            <Tooltip title="Assign Case">
              <Popover
                destroyTooltipOnHide
                overlayClassName="admin-cases__assignment-popover"
                placement="bottomLeft"
                content={
                  <div>
                    <CaseAssignmentForm
                      caseDetail={caseDetail}
                      onSuccess={handleAssignmentSuccess}
                    />
                  </div>
                }
                title="Assign case"
                trigger="click"
              >
                <Button
                  icon={<UserAddOutlined />}
                  className="ml-2"
                  onClick={() => {
                    setShowAssignmentPopover(!showAssignmentPopover);
                    setActiveCase(
                      activeCase?.id === caseDetail?.id ? undefined : caseDetail
                    );
                    setShowViewPopover(false);
                  }}
                />
              </Popover>
            </Tooltip>
            {caseDetail?.status !== CaseStatusEnum.UNASSIGNED && (
              <Tooltip title="View Access">
                <Popover
                  destroyTooltipOnHide
                  overlayClassName="admin-cases__assignment-popover"
                  placement="bottomLeft"
                  content={
                    <div>
                      <ViewAccessForm
                        caseDetail={caseDetail}
                        onSuccess={handleViewAccessSuccess}
                      />
                    </div>
                  }
                  title="Provide view access"
                  trigger="click"
                >
                  <Button
                    icon={<EyeOutlined />}
                    className="ml-2"
                    onClick={() => {
                      setShowViewPopover(!showViewPopover);
                      setActiveCase(
                        activeCase?.id === caseDetail?.id
                          ? undefined
                          : caseDetail
                      );
                      setShowAssignmentPopover(false);
                    }}
                  />
                </Popover>
              </Tooltip>
            )}
            {showGenerateReport && (
              <Tooltip title="Generate Report">
                <Button
                  icon={<FormOutlined />}
                  className="ml-2"
                  onClick={() => {
                    setActiveCase(caseDetail);
                    handleToggleReportForm();
                  }}
                />
              </Tooltip>
            )}
            {caseDetail?.status === CaseStatusEnum.REPORTED && (
              <Tooltip title="Download Report">
                <Popover
                  destroyTooltipOnHide
                  overlayClassName="admin-cases__assignment-popover"
                  placement="bottomLeft"
                  content={
                    <div>
                      <Menu mode="vertical" className="border-none">
                        <Menu.Item
                          className="navbar-item m-0"
                          key="with_letterhead"
                          onClick={() => {
                            handleDownloadReport(
                              caseDetail,
                              DownloadFileTypeEnum.PDF,
                              true
                            );
                          }}
                        >
                          Download with Letterhead
                        </Menu.Item>
                        <Menu.Item
                          className="navbar-item m-0"
                          key="without_letterhead"
                          onClick={() => {
                            handleDownloadReport(
                              caseDetail,
                              DownloadFileTypeEnum.PDF,
                              false
                            );
                          }}
                        >
                          Download without Letterhead
                        </Menu.Item>
                        <Menu.Item
                          className="navbar-item m-0"
                          key="as_word"
                          onClick={() => {
                            handleDownloadReport(
                              caseDetail,
                              DownloadFileTypeEnum.WORD,
                              false
                            );
                          }}
                        >
                          Download as Word
                        </Menu.Item>
                      </Menu>
                    </div>
                  }
                  title="Download Report"
                  trigger="click"
                >
                  <Button
                    loading={
                      activeCase?.id === caseDetail?.id && downloadLoading
                    }
                    icon={<DownloadOutlined />}
                    className="ml-2"
                  />
                </Popover>
              </Tooltip>
            )}
            <Tooltip title="Download Case">
              <Button
                loading={
                  activeCase?.id === caseDetail?.id && downloadZipLoading
                }
                icon={<CloudDownloadOutlined />}
                className="ml-2"
                onClick={() => {
                  handleDownloadDetails(caseDetail);
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <div className="admin-cases">
      <AppCard>
        <h2 className="mt-2">Cases</h2>
        <CaseFilterForm
          isAdmin
          formValues={formValues}
          onSubmit={handleSubmit}
          onReset={handleReset}
        />
        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          <Table
            dataSource={caseDetails}
            rowClassName="cursor-pointer"
            onRow={handleRowChange}
            columns={columns}
          />
        )}
      </AppCard>
      <Modal
        destroyOnClose
        visible={showCaseOverview}
        className="primary-modal"
        onCancel={handleCloseCaseOverview}
        footer={null}
        maskClosable={false}
      >
        {activeCase?.id && (
          <CaseOverview
            isAdmin
            activeCaseId={activeCase?.id}
            onCancel={handleCloseCaseOverview}
            onUpdateSuccess={handleCaseUpdateSuccess}
          />
        )}
      </Modal>
      <Drawer
        destroyOnClose
        title={<h2>Generate Report</h2>}
        width="70%"
        onClose={handleToggleReportForm}
        visible={showReportForm}
        bodyStyle={{ paddingBottom: 80 }}
        maskClosable={false}
        footer={null}
      >
        {activeCase?.id && (
          <ReportForm
            caseDetails={activeCase}
            caseDetailId={activeCase.id}
            onPublish={() => handleFetchCases(formValues)}
          />
        )}
      </Drawer>
    </div>
  );
}

export default AdminCases;
